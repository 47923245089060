.section {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.programma-section h2,
.programma-section p {
    margin: 10px 0;
    padding-top: 5%;
    font-size: 2rem;
}

.programma-section button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.programma-section button svg {
    display: block;
}

.programma-section ul {
    overflow-y: auto;
    max-height: 70%;
    padding: 0;
    list-style: none;
}

.programma-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    width: 80%;
}

.programma-item {
    flex: 1 1 calc(33.333% - 20px); /* Three columns */
    background-color: #E9E0D9;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    color: #523f39;
    transition: box-shadow 0.3s ease, max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
    max-height: 2em; /* Adjust as needed */
    overflow: hidden;
    text-align: left;
}

.programma-item.expanded {
    max-height: 300px; /* Adjust as needed */
}

.programma-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.programma-title {
    display: flex;
    font-size: 1.5em;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.programma-id {
    margin-right: 10px;
}

.programma-details {
    margin-top: 6px;
    color: #523f39;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    text-align: left;
    margin-left: 25px;
    font-size: 1.2rem;
}

.programma-group {
    border-top: 1px solid #523f39;
}



.arrow {
    transition: transform 0.5s ease-in-out;
  }
  
  .arrow.expanded {
    transform: rotate(180deg);
  }

/* Responsive Design */
@media (max-width: 900px) {
    .programma-item {
        flex: 1 1 100%; /* Single column */
        cursor: none;
    }
    
    .programma-section button {
        cursor: none;
        right: 5px;
    }

    .programma-section {
        top: -5%;
    }

}