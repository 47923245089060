.mes-section {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.up-arrow-button {
    position: absolute;
    top: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.up-arrow-button svg {
    display: block;
}

.interactive-logo {
    position: relative;
    top: -5%;
    width: 35vh; /* Adjust as needed */
    height: 35vh; /* Adjust as needed */
    justify-content: center;
    rotate: -45deg;
}

.word-button {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.6rem;
    color: #ffffff00;
    border-radius: 7px;
    font-family: "Teko", sans-serif;
    transform-origin: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: transform 0.2s ease;
}

.word-button:active {
    transform: scale(0.9);
}

/* Positioning the words */
.word-button.par {
    top: 13.5%;
    left: 3%;
    width: 30%;
    
}

.word-button.svetki {
    top: 75.5%;
    left: 6%;
    width: auto;

}

.word-button.balles {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 47%;
    left: 47%;
}

.word-button.koncerti {
    top: 44%;
    left: 6%;
    width: auto;
}

.word-button.celojumi {
    top: 44%;
    left: 72%;
    width: auto;
}   

.word-button.sauklis {
    top: 13.5%;
    left: 72%;
    width: auto;
}

.word-button.vesture {
    top: 75.5%;
    left: 77%;
    width: auto;
}

.word-button.citi {
    top: 75.5%;
    left: 77%;
    width: auto;
    display:none;
}

.word-button.secret1 {
    top: 15%;
    left: 47%;
    display: none;
}

.word-button.secret2 {
    top: 47%;
    left: 47%;
}

.word-button.secret3 {
    top: 78%;
    left: 47%;
}

.word-button.secret1,
.word-button.secret2,
.word-button.secret3 {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #E9E0D9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    display: none;
}


@media (max-width: 900px) {
    .word-button {
        cursor: none;
    }
}