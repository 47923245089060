.Ievads p{
    font-size: 1.5em;
    text-align: left;
    text-align: justify;
}

.Ievads{
    border-top: 1px solid #523f39;
    position: relative;
}

.Ievads img{
    margin-top: 10px;
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 10px;
}