
/* Add CSS for hiding doors after opening */
.Durvis {
  position: absolute;
  width: 50%;
  height: 100vh;
  background: #E9E0D9; /* A wood texture or color for medieval doors */
  border-style: solid;
  border-color: #523f39;
  transition: transform 2s ease-in-out;
  z-index: 10;

}

.Durvis.left {
  left: 0;
  transform-origin: 0 center;
  z-index:11;
}

.Durvis.right {
  right: 0;
  transform-origin: 100% center;
}

.Durvis.open {
  /* Instead of visibility: hidden, ensure the doors slide out */
  transform: translateX(-120%);
}

/* Corresponding CSS for the right door */
.Durvis.open.right {
  transform: translateX(120%);
}