.swipe-gallery {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .gallery-controls {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
  }
  
  .gallery-controls button {
    position: relative;
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: white;
    padding: 10px;
    border-radius: 50%;
  }
  
  .gallery-controls button:focus {
    outline: none;
  }
  
  @media (max-width: 900px){

    .gallery-controls button {
        cursor: none;
      }
  }

    
