@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');

body, html {
  height: 100%;
  margin: 0;
  overflow: hidden;
  background: #553C31;
  font-family: 'Teko', sans-serif;
  color: #E9E0D9;	
}

.main-content {
  top: 50%;
  text-align: center;
  color: #E9E0D9;
  opacity: 0;
  animation: fadeInContent 1.5s ease forwards 2s;
  transition: transform 0.5s ease-in-out;
}

.main-content h1 {
  font-size: 4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 2rem;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3), 0 0 15px rgba(255, 255, 255, 0.2); /* Subtle white glow */

}

nav ul {
  list-style: none;
  padding: 0;
  opacity: 0;
  animation: fadeInContent 1.5s ease forwards 4s;
}

nav ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 2rem;
  cursor: pointer;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li:hover {
  color: #c4b0a2;
}

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section.PROGRAMMA {
  left: -100vw;
  justify-content: unset;
  align-items: unset;
  /* background: linear-gradient(to right, #bababa 65%, #000000 110%); */
  
}

.section.MĒS {
  top: 100vh;
  justify-content: unset;
  align-items: unset;
  /* background: linear-gradient(to top, #bababa 65%, #000000 110%); */
}

.section.SEKO {
  left: 100vw;
  justify-content: unset;
  align-items: unset;
  /* background: linear-gradient(to left, #bababa 65%, #000000 110%); */
}


.container.active-PROGRAMMA .PROGRAMMA,
.container.active-PROGRAMMA .main-content {
  transform: translateX(100vw);
}

.container.active-MĒS .MĒS,
.container.active-MĒS .main-content {
  transform: translateY(-100vh);
}

.container.active-SEKO .SEKO,
.container.active-SEKO .main-content {
  transform: translateX(-100vw);
}

@keyframes fadeInContent {
  to {
    opacity: 1; 
  }
}


@media (max-width: 900px) {
  nav ul {
    list-style: none;  
    padding: 20px;
    text-align: center;
    cursor: none;
  }

  nav ul li {
    display: block;
    margin-right: 0;
    margin-bottom: 20px;
    cursor: none;
  }

  .main-content{
    margin-top: -15%;
  }
}