/* Modal.css */


.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s;
  }
  
  .modal-backdrop.show {
    visibility: visible;
    opacity: 1;
  }
  
  
  .modal-content {
    top: -5%;
    background: #E9E0D9;
    color: #553C31;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    max-width: 500px;
    width: 80vw;
    height: 70vh;
    z-index: 2;
    transform: translateY(40%) scale(0.2);
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    overflow-y: auto;
  }
  
  .modal-content.show {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 2;
  }
  

  @media (max-width: 900px){
    .close-button {
      cursor: none;
    }
    
  }