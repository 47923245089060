.logo {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition: transform 1s ease-out;
  z-index: 200;
  width: 15vh;
  height: 15vh;
  background-image: url('https://cdn.glitch.global/444da0f7-ddf2-492f-a73b-b8fc538e1f3d/usins_transparent.png?v=1713203192412');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  border-color: #523f39;
  border-style: solid;
  background-color: #e8dfda;
  box-shadow: 0 2px 4px rgba(0,0,0,0.6);
  cursor: pointer;
  
}

.logo:active {
  transform: translate(-50%, -50%) rotate(0deg);
}

.logo.twist {
  transform: translate(-50%, -50%) rotate(0deg);
}

.logo.hide {
  visibility: hidden;
}

@media (max-width: 900px) {
 
  .logo {
    width: 100px;
    height: 100px;
    top: 45%;
    cursor: none;
  }
}