
.koncerti p {
    font-size: 1.5rem;
    text-align: left;
    text-align: justify;
    margin-top: 20px;
    border-top: 1px solid #523f39;
    margin: 0;
}

#pirmais {
    border-top: 1px solid #523f39;
}