.seko-mums-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    color: #E9E0D9;
}

.seko-mums-section h2 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.seko-mums-section p {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 70%;
}


.seko-mums-section button {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #E9E0D9;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}

.social-icons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.social-icons svg {
    cursor: pointer;
}


@media (max-width: 900px) {

    .seko-mums-section button {
        cursor: none;
        left: 5px;
    }

    .seko-mums-section {
        top: -5%;
    }

}